:global(#app) {
  .message {
    display: flex;
    justify-content: space-between;
    background: none;
    color: var(--textColor1);
    border: 1px solid var(--textColor1);
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .warning {
    color: var(--textColorWarning);
    border: 1px solid var(--textColorWarning);

    .closeButton {
      svg {
        fill: var(--textColorWarning);

        &:hover {
          fill: var(--textColorWarningHover);
        }
      }
    }
  }

  .error {
    color: var(--textColorError);
    border: 1px solid var(--textColorError);

    .closeButton {
      svg {
        fill: var(--textColorError);

        &:hover {
          fill: var(--textColorErrorHover);
        }
      }
    }
  }
}
