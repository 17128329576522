:global(#app) {
  .buttons {
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    justify-content: flex-end;
    top: 0;
    padding: 8px 0 6px 6px;
  }

  .target {
    opacity: 0;
  }

  .date {
    display: block;
    color: var(--attachmentDate);
    line-height: 20px;
    margin-bottom: 6px;
  }

  .details {
    box-sizing: border-box;
    padding: 6px 32px 6px 128px;
    margin: 0;
    min-height: 80px;
  }

  .extension {
    color: var(--attachmentExtension);
    display: block;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 80px;
    overflow: hidden;
    padding: 0 20px 0 20px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: uppercase;
    width: 100%;
  }

  .name {
    color: var(--textColor1);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
  }

  .optionButton {
    color: var(--attachmentButton);
    padding: 0;

    &:hover {
      color: var(--attachmentButtonHover);
    }
  }

  .optionIcon {
    transform: rotate(180deg);
    vertical-align: middle;
  }

  .optionText {
    text-decoration: underline;
    margin-left: 5px;
  }

  .thumbnail {
    border-radius: 3px;
    background: var(--attachmentThumbnail);
    border-radius: 3px;
    height: 80px;
    left: 0;
    margin-top: -40px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 50%;
    width: 112px;
    overflow: hidden;
  }

  .imageSelected {
    position: absolute;
    top: -7px;
    left: -29px;
    height: 30px;
    width: 75px;
    transform: rotate(315deg);
    background: var(--overlay);
    padding: 10px;
  }

  .imageIcon {
    transform: rotate(-315deg);
  }

  .wrapper {
    margin-bottom: 8px;
    min-height: 80px;
    position: relative;

    &:hover {
      .details {
        background: var(--backgroundColorTmpBackgroundTasks);
      }

      .target {
        opacity: 1;
      }
    }
  }

  .wrapperSubmitting {
    display: flex;
    align-items: center;
    background: var(--backgroundColorTmpBackgroundTasks);
  }
}
