:global(#app) {
  .actionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .action {
    width: 100%;
    max-width: 750px;
  }
}
