:global(#app) {
  .button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 0;
    position: relative;
  }

  .initials {
    margin: 0 auto;
  }

  .wrapper {
    border: none;
    border-radius: 50%;
    color: var(--textColorWhite);
    display: inline-block;
    line-height: 1;
    outline: none;
    text-align: center;
    vertical-align: middle;
  }

  .wrapperHoverable:hover {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--userOverlay);
      border-radius: 50%;
    }
  }

  .wrapperRemovable:hover {
    &:before {
      position: absolute;
      content: '×';
      font-weight: 100;
      color: var(--userOverlayIcon);
      display: flex;
      justify-content: center;
      font-size: 24px;
    }
  }

  /* Sizes */

  .wrapperTiny {
    font-size: 10px;
    font-weight: 400;
    height: 24px;
    line-height: 20px;
    padding: 2px 0 2px 1px;
    width: 24px;
  }

  .wrapperSmall {
    font-size: 12px;
    font-weight: 400;
    height: 28px;
    padding: 8px 0;
    width: 28px;
  }

  .wrapperCard {
    font-size: 12px;
    font-weight: 400;
    height: 24px;
    padding: 6px 0;
    width: 24px;
  }

  .wrapperMedium {
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    padding: 10px 0;
    width: 32px;
  }

  .wrapperLarge {
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    padding: 12px 0 10px;
    width: 36px;
  }

  .wrapperMassive {
    font-size: 36px;
    font-weight: 500;
    height: 100px;
    padding: 32px 0 10px;
    width: 100px;
  }

  .wrapperProfile {
    font-size: 100px;
    font-weight: 500;
    height: 200px;
    padding: 48px 0 10px;
    width: 200px;
  }

  .wrapperCardTasks {
    font-size: 10px;
    font-weight: 400;
    height: 18px;
    line-height: 14px;
    padding: 2px 0;
    width: 18px;
  }

  /* Backgrounds */

  .backgroundEmerald {
    background: var(--emerald);
  }

  .backgroundPeterRiver {
    background: var(--peterRiver);
  }

  .backgroundWisteria {
    background: var(--wisteria);
  }

  .backgroundCarrot {
    background: var(--carrot);
  }

  .backgroundAlizarin {
    background: var(--alizarin);
  }

  .backgroundTurquoise {
    background: var(--turquoise);
  }

  .backgroundMidnightBlue {
    background: var(--midnightBlue);
  }
}
