:global(#app) {
  .field {
    width: 100%;
    padding-right: 26px;
    height: 36px;
    border-radius: 3px 0 0 3px;
  }

  .clearButton {
    margin-left: -24px;

    svg {
      vertical-align: middle;
      fill: var(--textColor3);
    }

    &:hover {
      svg {
        fill: var(--textColor4);
      }
    }
  }

  .paramsWrapper {
    display: flex;
  }

  .paramsButton {
    background: var(--backgroundColorTmpDarkerField2);
    border: solid var(--backgroundPopupSeparator);
    border-width: 1px 1px 1px 0;
    color: var(--textColor0);
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 7px 6px;

    &:hover {
      background: var(--backgroundColorTmpBackgroundHover);
    }
  }

  .paramsButtonActive {
    background: var(--backgroundColorTmpBackgroundHover);
  }
}
