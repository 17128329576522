:global(#app) {
  .content {
    color: var(--textColor1);
    text-align: center;
    margin-bottom: 6px;
    line-height: 20px;
  }

  .deleteButton {
    max-width: 300px;
  }
}
