:global(#app) {

  .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .loader {
    border: 4px solid var(--backgroundProject);
    border-radius: 50%;
    border-top: 4px solid var(--textColor3);
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  // Sizes

  .normal {
    width: 32px;
    height: 32px;
  }

  .massive {
    width: 64px;
    height: 64px;
  }
}
