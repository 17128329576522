:global(#app) {
  .button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    max-width: 100%;
    outline: none;
    padding: 0;
    position: relative;
  }

  .wrapper {
    border-radius: 3px;
    box-sizing: border-box;
    color: var(--textColorWhite);
    font-weight: 400;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 0 var(--shadowBlack02);
    white-space: nowrap;
  }

  .wrapperHoverable:hover {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--userOverlay);
    }
  }

  .wrapperRemovable:hover {
    &:before {
      position: absolute;
      content: '×';
      font-weight: 100;
      color: var(--userOverlayIcon);
      display: flex;
      justify-content: center;
      font-size: 24px;
      line-height: 20px;
    }
  }

  /* VARIANTS */

  .wrapperCard {
    font-size: 12px;
    line-height: 20px;
    max-width: 176px;
    padding: 1px 6px;
  }

  .wrapperCardModal {
    font-size: 14px;
    line-height: 28px;
    max-width: 176px;
    padding: 0 6px;
  }

  .wrapperLabels {
    font-size: 14px;
    line-height: 24px;
    max-width: 176px;
    padding: 0 6px;
  }
}
