:global {
  #app {
    //Root
    background: var(--backgroundColor);

    //MD Editor - Color Classes
    .black {
      color: var(--mdBlack);
    }

    .grey {
      color: var(--textColor1);
    }

    .white {
      color: var(--mdWhite);
    }

    .brown {
      color: var(--mdBrown);
    }

    .red {
      color: var(--mdRed);
    }

    .purple {
      color: var(--mdPurple);
    }

    .pink {
      color: var(--mdPink);
    }

    .green {
      color: var(--mdGreen);
    }

    .lime {
      color: var(--mdLime);
    }

    .yellow {
      color: var(--mdYellow);
    }

    .blue {
      color: var(--mdBlue);
    }

    .cyan {
      color: var(--mdCyan);
    }

    .orange {
      color: var(--mdOrange);
    }

    //MD Editor
    .w-md-editor {
      padding-bottom: 0;
    }

    .w-md-editor-bar {
      color: var(--textColor0);
      width: 30px;
    }

    .w-md-editor-toolbar {
      background-color: var(--backgroundColorTmpDarkerField);
    }

    .w-md-editor-area {
      background-color: var(--backgroundColorTmpDarkerField2);
      border-radius: 2px;
    }

    .w-md-editor-text {
      min-height: 100% !important;
    }

    .w-md-editor-text-input {
      &::selection {
        -webkit-text-fill-color: var(--textColorWhite);
      }
    }

    .w-md-editor-preview {
      background-color: var(--backgroundColorTmpDarkerField2);
      padding: 10px;

      .wmde-markdown {
        padding: 0;
        border-radius: 0;
      }
    }

    .wmde-markdown {
      padding: 10px;
      border-radius: 2px;
      background-color: var(--backgroundColorTmpDarkerField2);
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 14px;
    }

    .wmde-markdown > p {
      white-space: pre-wrap;
    }

    .wmde-markdown > ul > li > p {
      margin-top: 0px;
    }

    .wmde-markdown > ul {
      white-space: normal;
      line-height: 15px;
      padding-left: 0.8em;
    }

    .wmde-markdown > ul.contains-task-list {
      white-space: normal;
      line-height: 15px;
      padding-left: 1.3em;
    }

    .wmde-markdown > ul > li {
      white-space: pre-wrap;
    }

    .wmde-markdown > ol {
      white-space: normal;
      line-height: 15px;
      padding-left: 1em;
    }

    .wmde-markdown > ol > li {
      white-space: pre-wrap;
    }

    .wmde-markdown > hr {
      margin: 10px 0;
    }

    .wmde-markdown > h1,
    .wmde-markdown > h2,
    .wmde-markdown > h3,
    .wmde-markdown > h4,
    .wmde-markdown > h5,
    .wmde-markdown > h6 {
      margin: 12px 0 8px 0;
    }

    .wmde-markdown > blockquote > p {
      white-space: pre-wrap;
    }

    //Datepicker
    .react-datepicker {
      background: var(--backgroundList);
      border: 0;
      font-size: 14px;
      font-family: 'Museo Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: 400;
      width: 100%;
      padding-bottom: 8px;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
      color: var(--textColor1);
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      padding-bottom: 4px;
      text-transform: capitalize;
    }

    .react-datepicker__header {
      background: var(--backgroundList);
      border: 0;
      padding: 0;
    }

    .react-datepicker__day {
      background: var(--backgroundColor);
      border-style: solid;
      border-width: 0 0 1px 1px;
      border-color: var(--textColorCalendar);
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: var(--textColor1);
      display: inline-block;
      width: 40px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin: 0;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      border-radius: 0;
      background: var(--backgroundCardOpened);
      color: var(--textColorWhite);
      font-weight: bold;
    }

    .react-datepicker__day-name {
      border-bottom: 1px solid var(--textColorCalendar);
    }

    .react-datepicker__week {
      border-right: 1px solid var(--textColorCalendar);
    }

    .react-datepicker__day-names {
      background: var(--backgroundList);
      border: 0;
    }

    .react-datepicker__day--outside-month {
      color: var(--textColor4);
      background: var(--backgroundColorTmpSeparatorLight);
    }

    .react-datepicker__month {
      margin: 0.4rem 0 0;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      outline: none;
    }

    .react-datepicker__navigation--previous {
      left: 0;
    }

    .react-datepicker__navigation--next {
      right: 0;
    }

    .react-datepicker__navigation {
      border-width: 7px 9px;
      top: 0;
    }

    .react-datepicker__day {
      outline: none;

      &:hover {
        border-radius: 0;
      }
    }

    //Photoswipe
    .pswp .pswp__img--placeholder {
      display: none !important;
    }
  }
}
