:global(#app) {
  .checkbox {
    appearance: none;
    background: var(--backgroundColo0);
    border-radius: 3px;
    border: 1px solid var(--textColor3);
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1px;

    &:before {
      content: 'Г';
      color: var(--textColor2);
      transform: rotate(-135deg);
      visibility: hidden;
    }

    &:checked:before {
      visibility: visible;
    }

    &:hover {
      background: var(--backgroundColor2);
      cursor: pointer;
    }

    &:disabled {
      background: var(--backgroundDisabled);
      border: 1px solid var(--backgroundPopupSeparator);
      cursor: not-allowed;
    }
  }

  .checkboxError {
    border: 1px solid var(--errorRed);
  }

  .size14 {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    font-size: 11.2px;
  }

  .size20 {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    font-size: 16px;
  }
}
