:global(#app) {
  .textarea {
    background: var(--backgroundColorTmpDarkerField2);
    color: var(--textColor1);
    padding: 9px 8px;
    border: 1px solid var(--backgroundPopupSeparator);
    border-radius: 3px;
    resize: none;
    outline: 0;
    width: 100%;

    &::placeholder {
      color: var(--textColor3);
    }

    &:focus {
      border: 1px solid var(--borderOutline2);
    }
  }

  .textareaError {
    border: 1px solid var(--errorRed);

    &:focus {
      border: 1px solid var(--errorRed);
    }

    &::placeholder {
      color: var(--errorRed);
    }
  }

  .fullWidth {
    width: 100%;
  }

  .default {
    @extend .fullWidth;
    margin-bottom: 6px;
  }
}
