:global(#app) {
  --cardsInnerWrapperFullOffset: 191;
  --cardsInnerWrapperOffset: 227;
  --headerNameDefaultHeight: 20;

  .addCardButton {
    background: var(--backgroundList);
    color: var(--textColor3);
    cursor: pointer;
    display: block;
    fill: var(--textColor3);
    height: 36px;
    padding: 8px;
    text-align: left;
    width: 272px;

    &:hover {
      background: var(--backgroundListButton);
    }
  }

  .addCardButtonIcon {
    vertical-align: -25%;
    fill: var(--textColor3);
  }

  .addCardButtonText {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin-left: 2px;
    vertical-align: top;
  }

  .cards {
    min-height: 1px;
  }

  .cardsInnerWrapper {
    padding-top: 3px;
    width: 272px;
  }

  .cardsInnerWrapperScrollable {
    width: 269px;
  }

  .cardsOuterWrapper {
    padding: 0 6px;
    white-space: normal;
    width: 272px;
  }

  .cardsOuterWrapperScrollable {
    padding: 0 0 0 6px;
    white-space: normal;
    width: 258px;
  }

  .header {
    outline: none;
    padding: 4px 4px 4px 8px;
    position: relative;
    float: left;
  }

  .headerCollapsed {
    outline: none;
    height: calc(100vh - 191px);
  }

  .headerCollapsedInner {
    height: inherit;
    display: flex;
    flex-direction: column;
  }

  .popupWrapper {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 20px;
  }

  .editListButton {
    width: 10px;
  }

  .headerName {
    color: var(--textColor0);
    font-weight: bold;
    line-height: 20px;
    max-height: 40px;
    width: 240px;
    text-indent: 8px;
    position: relative;
    float: left;
    left: -10px;
    outline: none;
    overflow: hidden;
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .headerNameCollapsed {
    color: var(--textColor0);
    font-weight: bold;
    outline: none;
    overflow: hidden;
    writing-mode: vertical-rl;
    padding: 0px 5px 5px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: calc(100% - 90px);
    line-height: 18px;
  }

  .headerCollapseButton {
    z-index: 1;
    position: relative;
    float: left;
    left: -2px;
    width: 10px;
    height: 20px;
  }

  .iconRotateRight {
    transform: rotate(-90deg);
    vertical-align: middle;
  }

  .headerCollapseButtonCollapsed {
    height: 30px;
    width: 30px;
  }

  .headerCardsCount {
    color: var(--textColor3);
    font-size: 12px;
    position: relative;
    float: left;
  }

  .headerCardsCountCollapsed {
    color: var(--textColor3);
    font-size: 12px;
    writing-mode: vertical-rl;
    padding: 5px 5px 10px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 18px;
  }

  .innerWrapper {
    margin-right: 10px;
    width: 272px;
  }

  .innerWrapperCollapsed {
    margin-right: 10px;
    width: 30px;
  }

  .outerWrapper {
    background: var(--backgroundList);
    border-radius: 1px;
    overflow: hidden;
  }
}
