:global(#app) {
  .filter {
    background: var(--backgroundColorTmpDarkerField2);
    border: solid var(--backgroundPopupSeparator);
    border-width: 1px 1px 1px 0;

    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }

  .filterButton {
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 0;

    &:hover {
      background: var(--backgroundColorTmpBackgroundHover);
    }
  }

  .filterItem {
    background: var(--backgroundColorTmpDarkerField2);
    display: inline-block;
    font-size: 0;
    line-height: 0;
    margin-right: 4px;
    max-width: 190px;
    vertical-align: middle;
  }

  .lastFilterItem {
    margin-right: 0;
  }

  .filterTitle {
    border-radius: 3px;
    color: var(--textColor0);
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 7px 6px;
  }

  .popupWrapper {
    display: inline-block;
  }

  .clearButton {
    svg {
      vertical-align: middle;
      fill: var(--textColor3);
    }

    &:hover {
      svg {
        fill: var(--textColor4);
      }
    }
  }
}
