:global(#app) {
  .inputWrapper {
    position: relative;
  }

  .inputPassword {
    padding-right: 34px;
  }

  .passwordIcon {
    margin-left: -30px;
    vertical-align: middle;
  }

  .progressBar {
    position: absolute;
    opacity: 0.5;
    top: 31px;
    height: 4px;
    background-color: transparent;
  }
}
