:global(#app) {
  .progressContainer {
    width: calc(100% - 2px);
    margin: 2px 1px;
    background: var(--textColorWhite);
    border-radius: 3px;
    overflow: hidden;
  }

  .progressBar {
    transition: width 0.3s;
  }

  .red {
    background: var(--red);
  }

  .orange {
    background: var(--orange);
  }

  .yellow {
    background: var(--yellow);
  }

  .olive {
    background: var(--olive);
  }

  .green {
    background: var(--green);
  }

  /* Size classes */

  .tiny {
    height: 5px;
  }
}
