:global(#app) {
  .author {
    color: var(--textColor0);
    display: inline-block;
    font-weight: bold;
    line-height: 20px;
    margin-right: 8px;
    padding: 6px 0px 4px 0px;
  }

  .content {
    display: inline-block;
    margin-bottom: 16px;
    vertical-align: top;
    width: 100%;
  }

  .date {
    color: var(--textColor3);
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
  }

  .text {
    line-height: 20px;
  }

  .contentText {
    background: var(--backgroundColorTmpDarkerField);
    color: var(--textColor3);
  }

  .user {
    display: inline-block;
    padding: 4px 8px 0 0;
    vertical-align: top;
  }
}
