:global(#app) {
  .formTitle {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 32px;
    color: var(--textColor0);
    text-align: center;
  }

  .message {
    margin-bottom: 8px;
  }

  .logo {
    width: 100%;
    margin-top: 20px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: inherit;
    background: var(--backgroundColor0);
  }

  .loginWrapper {
    margin: auto;
    max-width: 440px;
    padding: 20px;
    background: var(--backgroundColor0);
  }

  .inputLabel {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
    color: var(--textColor3);
    width: 100%;
  }

  .ssoIcon {
    margin-left: 6px;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    gap: 4px;
  }

  .submitButton {
    margin-left: auto;
  }

  .submitButtonIcon {
    transform: rotate(270deg);
    vertical-align: text-bottom;
    margin-left: 6px;
  }

  .alternateActionText {
    color: var(--textColor3);
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin: 40px 0 16px 0;
  }

  .alternateActionButtonContainer {
    display: flex;
    justify-content: center;
  }
}
