:global(#app) {
  .popupWrapper {
    box-sizing: content-box;
    display: inline-block;
    min-height: auto;
    padding: 4px;
    position: absolute;
    right: 5px;
    top: 2px;
    width: 10px;
  }

  .popupWrapper2 {
    display: inline-block;
  }

  .editCardButton {
    opacity: 0;
  }

  .attachment {
    display: inline-block;
    line-height: 0;
    margin: 0 0 6px 0;
    max-width: 100%;
    vertical-align: top;
  }

  .attachmentLeft {
    margin-right: 4px;
  }

  .attachments {
    display: inline-block;
    padding-bottom: 2px;
  }

  .attachmentsRight {
    float: right;
    line-height: 0;
  }

  .card {
    background: var(--backgroundColorTmpHeader);
    border-radius: 2px;
    box-shadow: var(--cardShadow) 0px 1px 4px;
    position: relative;
    border: 2px solid transparent;

    &:hover {
      background: var(--backgroundCardSelected);

      .target {
        opacity: 1;
      }
    }
  }

  .cardOpen {
    box-shadow: 0 0 5px var(--borderOutline2);
    border-radius: 2px;
    border: 2px solid var(--borderOutline2);
    background: var(--backgroundCardOpened);
  }

  .content {
    display: block;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .cover {
    border-radius: 2px;
    padding: 0 2px;
    vertical-align: middle;
    width: 100%;
  }

  .detailsTitle {
    padding: 4px 8px 0 4px;
  }

  .details {
    padding: 4px 6px 0 4px;
  }

  .labels {
    display: block;
    max-width: 100%;
    overflow: hidden;
  }

  .detailsNotification {
    padding: 6px 0px 0;
  }

  .name {
    color: var(--textColor1);
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 6px;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 58px;
  }

  .notification {
    background: var(--notificationRed);
    color: var(--textColorWhite);
    font-size: 10px;
    line-height: 12px;
    padding: 0 2px 0 2px;
    border-radius: 3px;
    position: absolute;
    top: -3px;
    right: -3px;
  }

  .notificationFull {
    padding: 0 3px 0 2px;
  }

  .wrapper {
    cursor: pointer;
    display: block;
    margin-bottom: 8px;
  }

  .user {
    margin-right: -8px;
  }

  .moreUsers {
    color: var(--textColorWhite);
    background: var(--shadowBlack02);
    border-radius: 50%;
    text-align: center;
    padding-right: 2px;
    font-size: 12px;
    line-height: 25px;
    width: 24px;
    height: 24px;
  }

  .users {
    margin-right: 10px;
  }

  .detailsIcon {
    fill: var(--textColor3);
  }
}
