:global(#app) {
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(15vw);
    color: var(--textColor0);
  }

  .cardsCount {
    color: var(--textColorCounter);
    font-size: 12px;
  }

  .action {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .actionRightFirst {
    margin-left: auto;
  }

  .actionRightLast {
    margin-right: 0;
  }

  .wrapper {
    height: 68px;
    width: 100%;
    align-items: center;
    display: flex;
    padding: 16px 20px;
    background: var(--backgroundColor);
  }

  .githubAction {
    flex: 0 0 auto;
    margin-right: 6px;
  }

  .githubGreen {
    fill: var(--textColorGreen);
  }

  .githubGrey {
    fill: var(--textColor4);
  }
}
