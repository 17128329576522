:global(#app) {
  .button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    padding: 0;
  }

  .wrapper {
    border: none;
    border-radius: 3px;
    display: inline-block;
    outline: none;
    text-align: left;
    vertical-align: top;
  }

  /* Colors */

  .dueNormal {
    color: var(--dueDateNormal);
    fill: var(--dueDateNormal);
  }

  .dueClose {
    color: var(--dueDateClose);
    fill: var(--dueDateClose);
  }

  .dueOver {
    color: var(--dueDateOver);
    fill: var(--dueDateOver);
  }

  /* Variants */

  .wrapperCard {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
  }

  .wrapperCardModal {
    font-size: 14px;
    font-weight: bold;
    line-height: 28px;
    cursor: pointer;

    &:hover {
      filter: brightness(85%);
    }
  }

  .wrapperTasksCard {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    vertical-align: 0%;
  }
}
