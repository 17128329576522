:global(#app) {
  .dropzone {
    background: var(--backgroundColorTmpDarkerField2);
    opacity: 0.7;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2001;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropzoneText {
    color: var(--textColorWhite);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 20px;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
