:global(#app) {
  .wrapper {
    height: 100%;
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .header {
    color: var(--textColor0);
    margin-bottom: 20px;
    text-align: center;
  }

  .headerFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .headerText {
    display: inline;
    word-wrap: break-word;
    flex: 1 1 auto;
    margin: 0;
  }

  .demoMode {
    color: var(--errorRed);
    font-size: 14px;
    margin: 10px 0 0 0;
  }
}
