:root {
  --backgroundColor: #22252a; /* Override loading.css */
  --backgroundColor0: #1e1e1e;
  --backgroundColor1: #252526;
  --backgroundColor2: #333333;
  --backgroundList: #1f2d3c;
  --backgroundListButton: #1b2835;
  --backgroundCardSelected: #1c3546;
  --backgroundCardOpened: #11576b;
  --backgroundColorTmp0: #282c30;
  --backgroundColorTmp1: #292d33;
  --backgroundColorTmp2: #242d34;
  --backgroundColorTmp3: #1b2126;
  --backgroundColorTmpHeader: #2c3e51;
  --backgroundColorTmpBackground: #223140;
  --backgroundColorTmpBackgroundTasks: #1d2c3a;
  --backgroundColorTmpBackgroundHover: #121920;
  --backgroundColorTmpDarkerField: #1e2d39;
  --backgroundColorTmpDarkerField2: #17232c;
  --backgroundColorTmpSeparatorLight: #263443;
  --backgroundColorTmpHighlighted: #2d3b4b;
  --nodeOutline: rgba(54, 75, 97, 0.5);
  --borderOutline: #5ba4cf;
  --borderOutline2: #34b7cd;
  --selectionBackground: #0664d4;
  --loginBackground: #2185d0;
  --loginBackgroundDark: #2075b6;
  --textColorWhite: #ffffff;
  --textColor0: #ebebec;
  --textColor1: #d3d3d3;
  --textColor2: #c2c2c2;
  --textColor3: #999999;
  --textColor4: #6d6d6d;
  --textColor5: #363636;
  --textColorCalendar: #4d4d4d;
  --backgroundProject: #3b3b3b;
  --textColorGreen: #0bff44;
  --textColorYellow: #fdd41e;
  --textColorRed: #ff0000;
  --notificationRed: #eb5a46;
  --timerGreen: #21ba45;
  --timerGreenHover: #16ab39;
  --backgroundGreen: #5aac44;
  --backgroundGreenHover: #519839;
  --backgroundRed: #cf513d;
  --backgroundRedHover: #b04632;
  --backgroundSubmitDisabled: #89be7c;
  --backgroundCancelDisabled: #d9abab;
  --backgroundSidebar: #20303f;
  --backgroundSidebarItem: #162029;
  --backgroundSidebarItem2: #242d35;
  --backgroundSidebarItem3: #1c2935;
  --backgroundSidebarItemActive: #0c5064;
  --radioBlue: #2196f3;
  --backgroundRadio: #5b647e;
  --textColorLink: #2196f3;
  --textColorLinkHover: #1e88e5;
  --backgroundPopup: #2a3d50;
  --backgroundPopupHover: #233444;
  --backgroundPopupShadow: #19232e;
  --backgroundPopupSeparator: #5f6064;
  --textColorError: #ff6666;
  --textColorErrorHover: #be4e4e;
  --textColorWarning: #ffa600;
  --textColorWarningHover: #c57600;
  --headerButtonHover: #121316;
  --backgroundHeader: #1a1c20;
  --backgroundTabActive: #16181b;
  --textShadow: #000000;
  --attachmentDate: #6b808c;
  --attachmentExtension: #5e6c84;
  --attachmentButton: #6b808c;
  --attachmentButtonHover: #6b808cce;
  --attachmentThumbnail: #1e2a36;
  --modalOverlay: rgba(0, 0, 0, 0.8);
  --userOverlay: rgba(0, 0, 0, 0.5);
  --userOverlayIcon: rgba(255, 255, 255, 0.8);
  --overlay: rgba(0, 0, 0, 0.6);
  --backgroundDisabled: #201f1f;
  --scrollbarThumb: #757a82;
  --scrollbarThumbHover: #62676f;
  --scrollbarThumbActive: #32373e;
  --scrollbarTrack: #1c2532;
  --textColorCounter: #798d99;
  --mdEditorIcon: #c9d1d9;
  --mdEditorIconHover: #58a6ff;
  --mdEditorIconDisabled: #30363d;
  --textColorCounterHover: #72848f;
  --cardShadow: rgba(0, 0, 0, 0.16);
  --errorRed: #ff5740;
  --errorRedHover: #e64a33;
  --shadowBlack01: rgba(0, 0, 0, 0.1);
  --shadowBlack02: rgba(0, 0, 0, 0.2);
  --shadowBlack03: rgba(0, 0, 0, 0.3);
  /* Colors (right now only ProgressBar) */
  --red: #db2828;
  --orange: #f2711c;
  --yellow: #fbbd08;
  --olive: #b5cc18;
  --green: #21ba45;
  /* Colors (right now only UserAvatar) */
  --emerald: #2ecc71;
  --peterRiver: #3498db;
  --wisteria: #8e44ad;
  --carrot: #e67e22;
  --alizarin: #e74c3c;
  --turquoise: #1abc9c;
  --midnightBlue: #2c3e50;
  /* Colors (right now for DueDate) */
  --dueDateNormal: #999999;
  --dueDateClose: #fdd41e;
  --dueDateOver: #ff3030;
  /* Colors Markdown Editor */
  --mdBlack: #000000;
  --mdGrey: #d3d3d3;
  --mdWhite: #ffffff;
  --mdBrown: #aa4e0c;
  --mdRed: #ff0000;
  --mdPurple: #9b0b9b;
  --mdPink: #ff00ff;
  --mdGreen: #088f08;
  --mdLime: #00ff00;
  --mdYellow: #ffff00;
  --mdBlue: #006eff;
  --mdCyan: #00ffff;
  --mdOrange: #ffa500;
}
