:global(#app) {
  .backButton {
    position: absolute;
    left: 2px;
    top: 12px;
    z-index: 2000;
  }

  .content {
    color: var(--textColor0);
    border-bottom: 1px solid var(--backgroundPopupSeparator);
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    margin: 0 12px;
    padding: 12px 28px 8px;
    text-wrap: nowrap;
  }

  .wrapper {
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
  }
}
