:global(#app) {
  .item {
    padding: 12px;

    &:hover {
      background: var(--backgroundPopupHover);
    }
  }

  .itemButton {
    svg {
      fill: var(--textColor3);
    }

    &:hover {
      svg {
        fill: var(--textColor4);
      }
    }
  }

  .itemContent {
    display: inline-block;
    font-size: 12px;
    min-height: 36px;
    overflow: hidden;
    padding: 0 4px 0 8px;
    vertical-align: top;
    width: calc(100% - 56px);
    word-break: break-word;
  }

  .itemDeleted {
    display: inline-block;
    line-height: 20px;
    min-height: 20px;
    padding: 0 4px 0 8px;
    vertical-align: top;
    width: calc(100% - 20px);
  }

  .wrapper {
    margin: 0 -12px;
    max-height: 80vh;
  }
}
