:global(#app) {
  .headerButton {
    position: relative;
    flex: 0 1 auto;
  }

  .headerButtonOffset {
    position: relative;
    height: 32px;
    flex: 1 1 auto;
  }

  @media (max-width: 500px) {
    .headerButtonOffset {
      display: none;
    }
  }
}
