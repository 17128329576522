:global(#app) {
  .editButton {
    width: 36px;
    height: 36px;
    padding: 8px 10px;

    svg {
      fill: var(--textColor1);
    }

    &:hover {
      svg {
        fill: var(--textColor2);
      }
    }
  }

  .name {
    border-radius: 3px;
    color: var(--textColorWhite);
    cursor: pointer;
    flex: 1 1 auto;
    font-weight: bold;
    overflow: hidden;
    padding: 8px 32px 8px 10px;
    position: relative;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 0 var(--textShadow);

    &:hover {
      opacity: 0.9;
    }
  }

  .nameActive:before {
    bottom: 1px;
    content: "Г";
    font-size: 18px;
    font-weight: normal;
    line-height: 36px;
    position: absolute;
    right: 2px;
    text-align: center;
    transform: rotate(-135deg);
    width: 36px;
  }

  .wrapper {
    display: flex;
    margin-bottom: 4px;
    max-width: 280px;
    white-space: nowrap;
  }
}
