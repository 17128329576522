:global(#app) {
  .actionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .action {
    width: 100%;
    max-width: 750px;
  }

  .projectName {
    color: var(--textColor1);
    margin: 10px auto;
    max-width: 750px;
    word-wrap: break-word;
    text-align: center;
  }

  .dangerZone {
    color: var(--textColorError);
    font-weight: bold;
    text-align: center;
    padding-top: 8px;
    margin-top: 4px;
    border-top: 1px solid var(--backgroundPopupSeparator);
  }

  .text {
    color: var(--textColor1);
    margin-bottom: 4px;
  }

  .headerButton {
    position: relative;
    float: right;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .headerButtonOffset {
    position: relative;
    float: left;
    width: 20px;
    height: 32px;
  }

  @media (max-width: 700px) {
    .headerButtonOffset {
      width: 0;
      height: 0;
    }
  }
}
