:global(#app) {
  .wrapper {
    position: fixed;
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
  }

  .content {
    flex: 1;
    height: 100%;
    width: calc(100% - 230px);
  }

  .contentCompact {
    width: calc(100% - 180px);
  }

  .sidebar {
    background-color: var(--backgroundSidebar);
    color: var(--textColor0);
    width: 230px;
    height: 100%;
    box-shadow: 2px 0 5px var(--shadowBlack01);
    display: flex;
    flex-direction: column;
  }

  .sidebarCompact {
    width: 180px;
  }

  .sidebarHidden {
    width: 0px;
    visibility: hidden;
  }

  .scrollable {
    flex-grow: 1;
  }

  .toggleSidebarButton {
    position: absolute;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -24px;
    left: 6px;
    background-color: var(--backgroundHeader);
  }

  .sidebarTitle {
    color: var(--textColorWhite);
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0 10px 6px;
  }

  .sidebarTitleIcon {
    margin-right: 6px;
    vertical-align: -10%;
    fill: var(--textColorWhite);
  }

  .sidebarItem {
    padding: 0 10px;
    background: var(--backgroundSidebarItem);
  }

  .hoverButton {
    opacity: 0;
  }

  .boardDraggable {
    &:hover {
      .hoverButton {
        opacity: 1;
      }
    }
  }

  .sidebarItemProject {
    background: var(--backgroundSidebarItem3);
    border-top: 1px solid var(--backgroundPopupSeparator);
    display: flex;
    align-items: center;

    &:hover {
      .hoverButton {
        opacity: 1;
      }
    }
  }

  .sidebarItemBoard {
    background: var(--backgroundSidebarItem);
    padding: 8px 0;
    display: flex;
  }

  .sidebarItemInner {
    flex: 1 1 0;
    padding: 0;
    min-width: 0;
  }

  .sidebarItemActive {
    background: var(--backgroundSidebarItemActive);
    border-left: 2px solid var(--borderOutline2);

    button {
      font-weight: bold;
    }

    &:hover {
      button {
        color: var(--textColor0);
      }
    }

    button {
      &:hover {
        svg {
          fill: var(--borderOutline2) !important;
        }
      }
    }
  }

  .sidebarActive {
    background: var(--backgroundSidebarItemActive);
    border-left: 2px solid var(--borderOutline2);

    button {
      font-weight: bold;
    }

    &:hover {
      button {
        color: var(--textColor0);
      }

      svg {
        fill: var(--textColor0) !important;
      }
    }
  }

  .sidebarButton {
    color: var(--textColor0);

    svg {
      fill: var(--textColor0);
    }

    &:hover {
      color: var(--textColor1);

      svg {
        fill: var(--textColor1);
      }
    }
  }

  .icon {
    margin-right: 4px;
    fill: var(--textColor0);
  }

  .sidebarButtonPadding {
    padding: 8px 8px 8px 0px;
  }

  .githubGreen {
    fill: var(--textColorGreen);
  }

  .githubGrey {
    fill: var(--textColor0);
  }

  .githubCannotManage {
    margin-right: 20px;
  }

  .boardButton {
    padding: 0;
  }

  .boardCannotManage {
    margin-left: 13px;
  }

  .collapseIcon {
    vertical-align: middle;
  }

  .collapseIconCollapsed {
    transform: rotate(270deg);
  }

  .footerButton {
    padding: 8px 14px;
  }

  .footerButtonIcon {
    margin-right: 4px;
  }

  .reorderBoardsButton {
    width: 13px;
  }
}
