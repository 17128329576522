:global(#app) {
  .droppableHack {
    display: none;
    position: fixed;
  }

  .items {
    margin-top: 8px;
    max-height: 60vh;
  }
}
