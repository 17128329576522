:global(#app) {
  .button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .default {
    background: var(--backgroundColorTmpDarkerField2);
    color: var(--textColor1);
    overflow: hidden;
    padding: 8px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 3px;
    line-height: 20px;
    width: 100%;
    height: 100%;

    &:hover {
      color: var(--textColor3);

      svg {
        fill: var(--textColor3);
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--textColor4);

      svg {
        fill: var(--textColor4);
      }
    }
  }

  .defaultBorder {
    @extend .default;
    border: 1px solid var(--backgroundPopupSeparator);
    text-wrap: wrap;
  }

  .noBackground {
    color: var(--textColor1);
    overflow: hidden;
    padding: 8px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 3px;
    line-height: 20px;
    width: 100%;
    height: 100%;

    &:hover {
      color: var(--textColor3);

      svg {
        fill: var(--textColor3);
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--textColor4);

      svg {
        fill: var(--textColor4);
      }
    }
  }

  .iconButton {
    padding: 0;
    width: 20px;
    height: 20px;

    &:hover {
      svg {
        fill: var(--textColor3);
      }
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        fill: var(--textColor4);
      }
    }
  }

  .login {
    background: var(--loginBackground);
    color: var(--textColor0);
    border-radius: 3px;
    padding: 8px 12px;
    width: auto;
    height: 45px;
    font-weight: bold;
    overflow: hidden;

    svg {
      fill: var(--textColor0);
    }

    &:hover {
      background: var(--loginBackgroundDark);
    }
  }

  //TODO think about multiline buttons - word-wrap: break-word and min-height instead of white-space, text-overflow
  .controlButtons {
    color: var(--textColorWhite);
    border-radius: 3px;
    height: 32px;
    width: auto;
    padding: 4px 6px 3px 6px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .submit {
    @extend .controlButtons;
    background: var(--backgroundGreen);
    margin-left: 6px;

    &:hover {
      background: var(--backgroundGreenHover);
    }

    &:disabled {
      background: var(--backgroundSubmitDisabled);
    }
  }

  .cancel {
    @extend .controlButtons;
    background: var(--backgroundRed);

    &:hover {
      background: var(--backgroundRedHover);
    }

    &:disabled {
      background: var(--backgroundCancelDisabled);
    }
  }

  .popup {
    background: var(--backgroundPopup);
    color: var(--textColor1);
    overflow: hidden;
    padding: 8px 16px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 3px;
    line-height: 20px;
    width: 100%;
    height: 100%;

    &:hover {
      background: var(--backgroundPopupHover);
      color: var(--textColor0);

      svg {
        fill: var(--textColor0);
      }
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--textColor4);

      svg {
        fill: var(--textColor4);
      }
    }

    //TODO temp disabled - until the popup button focus is handled correctly
    // &:focus {
    //   outline: none;
    //   box-shadow: inset 0px 0px 0px 1px var(--borderOutline2);
    // }
  }

  .popupContext {
    @extend .popup;
    padding: 8px 16px 8px 8px;
  }

  .header {
    padding: 0;
    width: 50px;
    height: 50px;

    &:hover {
      background: var(--headerButtonHover);
    }
  }

  .headerLogo {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}
