:global(#app) {
  .version {
    color: var(--textColor0);
    font-weight: bold;
    text-align: center;
  }

  .demoMode {
    color: var(--errorRed);
    font-weight: bold;
    text-align: center;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 35%;
  }

  @media (max-width: 700px) {
    .logo {
      width: 100%;
    }
  }

  .links {
    color: var(--textColor0);
    margin: 20px 0;
    text-align: center;
  }

  .link {
    margin-bottom: 5px;

    svg {
      fill: var(--textColorLink);
    }

    &:hover {
      svg {
        fill: var(--textColorLinkHover);
      }
    }
  }

  .icon {
    margin-right: 5px;
    vertical-align: -5%;
  }

  .button {
    width: fit-content;
    text-align: center;
  }
}
