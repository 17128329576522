:global(#app) {
  .editbutton {
    svg {
      fill: var(--textColor1);
    }

    &:hover {
      svg {
        fill: var(--textColor2);
      }
    }
  }

  .row {
    td {
      max-width: 200px;
    }
  }

  .currentUser {
    font-style: italic;
    color: var(--textColor3);
  }
}
