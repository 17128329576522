:global(#app) {
  .comments {
    margin-top: 16px;
  }

  .toggleButton {
    color: var(--textColor1);
    float: right;
    width: auto;
    height: 20px;

    &:hover {
      color: var(--textColor3);
    }
  }

  .icon {
    vertical-align: baseline;
  }
}
