:global(#app) {
  .coloredTextButton {
    svg {
      vertical-align: -5%;
      fill: var(--mdEditorIcon);
    }

    &:hover {
      svg {
        fill: var(--mdEditorIconHover);
      }
    }

    &:disabled {
      svg {
        fill: var(--mdEditorIconDisabled);
      }
    }
  }
}
