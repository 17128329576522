:global(#app) {
  .field {
    margin-bottom: 6px;
  }

  .text {
    color: var(--textColor1);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
  }
}
