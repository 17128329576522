:global(#app) {
  .content {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .contentPdf {
    height: 100%;
    width: 1120px;
  }

  .contentError {
    color: var(--textColor0);
    font-size: 20px;
    font-weight: 700;
    height: 20px;
    width: 470px;
  }

  .toggleButton {
    color: var(--textColor3);
    padding: 6px 4px;

    &:hover {
      color: var(--textColor4);
    }
  }
}
