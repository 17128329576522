:global(#app) {
  .wrapper {
    background: var(--backgroundHeader);
    display: flex;
    width: 100%;
    height: 50px;
  }

  .logo {
    display: inline-block;
    margin: 0 12px 0 22px;
  }

  .logoIcon {
    margin-top: 4px;
    height: 40px;
  }

  .title {
    color: var(--textColor0);
    font-size: 20px;
    font-weight: bold;
    padding: 14px 10px 14px 10px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: auto;
  }

  .menuRight {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 600px) {
    .hideOnSmall {
      display: none;
    }
  }

  .notification {
    position: absolute;
    background: var(--notificationRed);
    border-radius: 8px;
    color: var(--textColorWhite);
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    text-align: center;
    transform: translate(-8px, -6px);
    width: 16px;
  }
}
