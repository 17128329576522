:global(#app) {
  .icon {
    fill: var(--textColor1);
    vertical-align: sub;
  }

  .size8 {
    width: 8px;
    height: 8px;
  }

  .size10 {
    width: 10px;
    height: 10px;
  }

  .size12 {
    width: 12px;
    height: 12px;
  }

  .size13 {
    width: 13px;
    height: 13px;
  }

  .size14 {
    width: 14px;
    height: 14px;
  }

  .size16 {
    width: 16px;
    height: 16px;
  }

  .size18 {
    width: 18px;
    height: 18px;
  }

  .size20 {
    width: 20px;
    height: 20px;
  }
}
