:global(#app) {
  .field {
    margin-bottom: 4px;
    width: 100%;
  }

  .importButton {
    width: auto;
    overflow: hidden;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdownMenu {
    z-index: 2001;
  }

  .text {
    color: var(--textColor1);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .submitButton {
    width: auto;
  }

  .checkboxesWrapper {
    width: 100%;
    margin-bottom: 6px;
  }

  .checkboxWrapper {
    margin: 6px 0 4px 0;
    display: inline-block;
    vertical-align: middle;
  }

  .checkbox {
    background: var(--backgroundColorTmpBackground);

    &:hover {
      background: var(--backgroundColorTmpBackgroundHover);
    }
  }

  .checkboxText {
    padding-left: 4px;
    display: inline-block;
    width: calc(100% - 20px);
    vertical-align: middle;
  }
}
