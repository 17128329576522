:global(#app) {
  .closeButton {
    position: absolute;
    right: 4px;
    top: 6px;
    z-index: 2000;
  }

  .popup {
    background: var(--backgroundPopup);
    color: var(--textColor1);
    width: min-content;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 70px);
    border-radius: 3px;
    text-align: left;
    z-index: 2000;
    box-shadow: -1px -1px 3px var(--backgroundPopupShadow), 1px 1px 3px var(--backgroundPopupShadow);
  }
}
